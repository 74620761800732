<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('scheduledMessages') }}</h3>
  </div>
  <paginated-table-wrapper
    v-if="license"
    :sort="'desc'"
    :license="license"
    :request="getScheduledMessagesRequest"
    :csv-handler="downloadCsvHandler"
    v-slot="{ list, license, isLoading }"
  >
    <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
    <paginated-table
      :list="list"
      :license="license"
    ></paginated-table>
  </paginated-table-wrapper>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { getScheduledMessages } from '@/application/services/statistic.js'
import { store } from '@/application/store'
import { useCsvExport } from '@/application/composables/csvExport.js'
import { formatterCSVScheduledMessages } from '@/application/utils/csv.js'
import { useI18n } from 'vue-i18n'

export default {
  name: 'ScheduledCampaignsPage',
  components: {
    'paginated-table-wrapper': defineAsyncComponent(() => import('@/application/components/elements/PaginatedTable/Wrapper.vue')),
    'paginated-table': defineAsyncComponent(() => import('@/application/components/elements/message-tables/ScheduledMessagesTable.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    const i18n = useI18n()
    const license = computed(() => store.getters.getLicense)
    const getScheduledMessagesRequest = getScheduledMessages
    const {
      downloadCsvHandler,
    } = useCsvExport(
      'scheduled-messages.csv',
      formatterCSVScheduledMessages,
      {
        recipients: i18n.t('recipient'),
        text: i18n.t('text'),
        status: i18n.t('status'),
        sandbox: i18n.t('sandbox'),
        date: i18n.t('date'),
      }
    )
    return {
      license,
      getScheduledMessagesRequest,
      downloadCsvHandler,
    }
  },
}
</script>
